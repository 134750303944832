import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/modules/auth'

const request = axios.create({
  headers: {
    post: {
      Accept: 'application/json'
    },
    common: {
      Accept: 'application/json'
    }
  },
  baseURL: window.location.origin,
  timeout: 30000
})

request.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers.Authorization = 'Bearer ' + getToken()
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  response => response,
  error => {
    if (error.response.data instanceof Blob) {
      const reader = new FileReader()
      reader.onload = e => {
        Message({
          message: JSON.parse(e.target.result).message,
          type: 'error',
          duration: 10000
        })
      }
      reader.readAsText(error.response.data)
    } else {
      let message = error.response.data.message
      if (!message && Array.isArray(error.response.data)) {
        message = error.response.data.map(x => x.message).join('\n')
      }
      Message({
        message,
        type: 'error',
        duration: 10000
      })
    }
    return Promise.reject(error)
  }
)

export default request
